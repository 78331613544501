import products from '../../../sharedTypes/products';
import constants from '../../constants';

const { states } = constants;

export default {
  product: products.PAYMENTS,
  state: states.APP_AUTH_REQUIRED,
  stateContext: constants.state_context.PAYMENT_AUTH,
  frozenConfig: {
    links: {
      challenges: ['123456'],
    },
    payments: {
      contactMfaCounter: 0,
      paymentMfaCounter: 0,
    },
    widget: {
      entityIdSelected: 'cl_banco_falabella',
    },
    config: {
      selectedCountry: 'cl',
      organizationId: 'org_xxx',
    },
  },
};
